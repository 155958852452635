import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";


import BachesApp from "images/clientes/2017-baches-app.png";
import Colmex from "images/clientes/2021-colmex.gif";
import Cide from "images/clientes/2021-cide.png";
import Hidalgo from "images/clientes/2021-hidalgo.png";
import Talentum from "images/clientes/2021-talentum-mujeres.png";
import Seeiem from "images/clientes/2019-mantenimeinto-SEIEM-App.png";

import PropuestasNoPromesas from "images/clientes/2021-propuestas-no-promesas.svg";
import PXG from "images/clientes/2020-PXG.png";
import Clorimex from "images/clientes/2019-clorimex.png";
import Hercules from "images/clientes/2017-hercules.png";
import Roadpatch from "images/clientes/2017-roadpatch.png";
import Monitoreo from "images/clientes/2018-observatorio_legislativo_parlamentoedomex.png";
import Campostelo from "images/clientes/2021-campostelo.png";
import Typsa from "images/clientes/2020-Typsa.jpeg";
import MediApp from "images/clientes/2020-medi-app.png";
import Partidos from "images/clientes/partidos.png";
import Gobierno from "images/clientes/gobierno.jpeg";


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-6 flex-shrink-0`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Nuestros clientes", subheading = "Features", description = "Algunos de nuestros más recientes clientes" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: Partidos, title: "Partidos políticos", description: "Gobierno" },
    { imageSrc: Gobierno, title: "Gobiernos estatales", description: "Gobierno" },
    { imageSrc: Colmex, title: "Colegio de México", description: "Gobierno" },
    { imageSrc: Cide, title: "CIDE", description: "Gobierno" },
    { imageSrc: Talentum, title: "Talentum mujeres", description: "Gobierno" },
    { imageSrc: PropuestasNoPromesas, title: "Propuestas no promesas", description: "Asociación civil" },
    { imageSrc: BachesApp, title: "Baches App", description: "Empresa privada"},
    { imageSrc: PXG, title: "PXG México", description: "Empresa privada" },
    { imageSrc: Clorimex, title: "Clorimex", description: "Empresa privada" },
    { imageSrc: Roadpatch, title: "Roadpatch", description: "Empresa privada" },
    { imageSrc: Monitoreo, title: "Observatorio Legislativo", description: "Empresa privada" },
    { imageSrc: Campostelo, title: "Campostelo", description: "Empresa privada" },
    { imageSrc: Typsa, title: "Typsa", description: "Empresa privada" },
    { imageSrc: Hercules, title: "Hercules", description: "Empresa privada" },
    { imageSrc: MediApp, title: "MediApp", description: "Empresa privada" },
   
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/*subheading && <Subheading>{subheading}</Subheading>*/}
        <Heading>{heading}</Heading>
        {/*description && <Description>{description}</Description>*/}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer" style={{verticalAlign:"middle", marginTop:'10%'}}>
       
                <span className="title">{card.title || "Fully Secure"}</span>
               {/* <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                  </p> */}
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
