import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import Valores from "images/demo/valores_.png";


const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

const ImageSquare = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-8/12 lg:w-8/12 xl:w-8/12 flex-shrink-0 h-80 md:h-144 bg-contain bg-no-repeat mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://mapaturistico.net/wp-content/uploads/dfdesdeelcielo-2.jpeg",
      subtitle: "Nacimiento",
      title: "El inicio de la empresa",
      description:
        "Odvz CONSULTORES fue fundada en 2015, su nacimiento se incerta en la tendencia a la alta especialización que caracteriza al sector de la consultoría. Desde sus inicios se caracterizó por  la detección de necesidades contextuales derivados de los cambios político-sociales a los que se enfrentaba nuestro país, atendiendo principalmente a la gestión privada ofreciendo servicios de asesoría, auditoria y asistencia técnica. ",
    },

    {
      imageSrc:
        "https://www.7clean.mx/hubfs/Cua%CC%81l%20es%20la%20diferencia%20entre%20afiliado,%20socio%20y%20franquicia.jpg",
      subtitle: "Primeros años",
      title: "Integración de nuevos socios",
      description:
        "Un par de años después,  con la integración de nuevos socios, Odvz CONSULTORES adquiere su visión actual. Se trata de una consultoría que combina los servicios especializados en administración privada y gestión pública y que incluye -dentro de sus soluciones integrales- servicios pertenecientes a la rama tecnología, importante para atender las necesidades actuales de cualesquiera de nuestros clientes.",

    },

    {
      imageSrc:
        "https://visionindustrial.com.mx/wp-content/uploads/2020/06/crecimiento-empresarial-ch.jpg",
      subtitle: "Crecimiento",
      title: "Nuevos horizontes",
      description:
        "El crecimiento de organización responde a la demanda de profesionalización de la gestión pública, a la atención personalizada de las necesidades de nuestros clientes y a la consciencia de la necesidad de gestionar de manera óptima los recursos disponibles.",
      url: "https://timerse.com"
    }
  ];

  return (
    <Container>
      <SingleColumn>

      <HeadingInfoContainer>
          <HeadingTitle>Acerca de nosotros</HeadingTitle>
      
        </HeadingInfoContainer>


     




        <Content>

        <Title>Misión</Title>
         <Description style={{textAlign:"justify"}}>
         Odvz es una organización dedicada a la innovación estratégica; en ella se conjugan elementos de modernización tecnológica y  análisis transversal frente a los desafíos y retos de nuestra sociedad actual. Se aplican los diseños mejor evaluados de acciones públicas en las esferas políticas, jurídicas y de desarrollo organizacional. Está conformada por consultores especializados en diversas áreas del conocimiento con una visión integral que ofrece resoluciones eficaces y propuestas creativas. 
         </Description>


         <Title>Visión</Title>
         <Description style={{textAlign:"justify"}}>
         Posicionarse en un periodo de 3 años como una organización referente en el servicio de consultoría política, jurídica y tecnológica, creadora de soluciones innovadoras y de resultados efectivos. OdVZ será reconocida por ofrecer las mejores propuestas del mercado, con atención profesional a los clientes, así como soluciones rápidas y eficaces. Al interior, la organización permanecerá como un espacio de diálogo permanente con los asociados que ofrece espacios creativos de implementación.           </Description>

      


         <center><ImageSquare imageSrc={Valores} style={{margin:25}} /></center>
          



         <HeadingInfoContainer>
          <HeadingTitle>Nuestra historia</HeadingTitle>
          <HeadingDescription>
          Una breve reseña de ODVZ al paso de los años.
          </HeadingDescription>
        </HeadingInfoContainer>


          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description style={{textAlign:"justify"}}>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
