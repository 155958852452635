import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Hero from "components/hero/BackgroundAsImage.js";

import Footer from "components/footers/MiniCenteredFooter";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import josera from "images/socios/josera.jpg";
import danae from "images/socios/danae.jpg";
import fernando from "images/socios/fernando.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Hero />

     {/* <MainFeature1
        subheading={<Subheading>Socio consultor</Subheading>}
        heading="José Ramón Orozco"
        buttonRounded={false}
        primaryButtonText={null}
        showButton={false}
        imageSrc={josera}
        description={"Es licenciado en Ciencia Política y Relaciones Internacionales por el Centro de Investigación y Docencia Económicas (CIDE). Cursó la maestría en Gerencia Pública en el CIDE y concluyó un bootcamp de programación full-stack en el Tecnológico de Monterrey. "}
        semblance={"Ha laborado en consultoras privadas y en el sector público en áreas relacionadas con gobierno digital; innovación de herramientas funcionales mediante el uso de las TICs, mejora de procesos, quality assurance, documentación del desarrollo de software, inteligencia electoral y gerencia de campañas políticas (campaign management). También tiene experiencia como asesor legislativo de diputados locales o federales."}
     /> */}
      <MainFeature1
        subheading={<Subheading>Socia consultora</Subheading>}
        heading="Danae Villanueva"
        buttonRounded={false}
        primaryButtonText="Contact Us"
        showButton={false}
        imageSrc={danae}
        textOnLeft={false}
        description={"Licenciada en psicología  por la Universidad Nacional Autónoma de México (UNAM), Master en Estudios Políticos Aplicados por la Universidad Ortega y Gasset en Madrid, España. "}
        semblance={"Ha participado  en diferentes Misiones de Observación Electoral con la Organización de Estados Americanos (OEA)."}
        semblanceExtend={"Desde el 2012 se dedica a la asesoría en temas de  gasto programado  para diferentes partidos políticos."}
      />

      <MainFeature1
        subheading={<Subheading>Socio consultor</Subheading>}
        heading="Fernando Zúñiga"
        buttonRounded={false}
        primaryButtonText={null}
        showButton={false}
        imageSrc={fernando}
        description={"Es licenciado en Derecho por el Centro de Investigación y Docencia Económicas (CIDE) en donde fue distinguido como el mejor promedio de su generación y titulado con mención honorífica. Es maestro en Derecho Administrativo y Maestro en Derecho Económico con Mención Honorífica por la Universidad Panamericana. Concluyó sus estudios de Doctorado en Derecho en la Universidad Panamericana y del Máster en Argumentación Jurídica de la Universidad de León, España y la Editorial Tirant Lo Blanch.  Cursa la especialidad en Derecho Procesal Electoral impartida por el Instituto Electoral del Estado de México."}
        semblance={"Se especializa en las materias de Derecho Electoral y Fiscalización Electoral, Derecho Administrativo y Regulatorio. Es docente en la facultad de Derecho de la Universidad La Salle, conferencista nacional e internacional."}
      />

     {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem iptetur  elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      />*/}
      <TeamCardGrid 
      heading="Síguenos en Twitter"
        subheading={null}
        description="Conoce un poco más sobre nosotros en Twitter"
      /> 
      <Footer />
    </AnimationRevealPage>
  );
};
