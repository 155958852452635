import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingLg } from "components/misc/Layouts";
import tw from "twin.macro";
import { motion } from "framer-motion";

import styled from "styled-components";
import { css } from "styled-components/macro";
import Hero from "components/hero/BackgroundAsImage.js";

import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";

import Fernando from "images/socios/ferBlog.png";
import RazonYLey from "images/demo/razon-y-ley.jpeg";


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;

const AuthorInfo = tw.div`mt-6 flex items-center`;
const AuthorImage = tw.img`w-12 h-12 rounded-full`;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-lg`;
const AuthorProfile = tw.p`text-secondary-100 text-sm`;

const postBackgroundSizeAnimation = {
  rest: {
    backgroundSize: "100%"
  },
  hover: {
    backgroundSize: "110%"
  }
};

const PostImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-32 sm:h-64 bg-center bg-contain rounded-t bg-no-repeat`}
`;

const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
/*const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;*/

const Image = styled(motion.div)(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`
]);

const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Blog",
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Hero />
      <Container>
        <ContentWithPaddingLg>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>

          <PostImage imageSrc={RazonYLey} />



          <Title style={{marginTop:25}}>RAZON Y LEY </Title>

<Category>Revocación de Mandato, entenderla desde el principio</Category>
<CreationDate> 28 de marzo del 2022</CreationDate>

<AuthorInfo>
  <AuthorImage src={Fernando} />
  <AuthorNameAndProfession>
    <AuthorName><b>Fernando Zúñiga</b></AuthorName>
    <AuthorProfile>Socio</AuthorProfile>
  </AuthorNameAndProfession>
</AuthorInfo>




<Info style={{textAlign: 'justify'}}>
<p aria-level="1"><span data-contrast="auto">La revocación de mandato en nuestro país ha sido objeto de todo tipo de opiniones; hoy por hoy seguimos escuchando debates respecto de la conveniencia, o no, de participar en ella. No faltan las voces que la critican, porque más que revocación parece ratificación de mandato; y desde luego, tampoco son menos las voces que la apoyan, porque se trata de un instrumento inédito de democracia directa.</span></p><br/>
<p><span data-contrast="auto">En medio de todos estos puntos de vista, y sea cual sea nuestra posición respecto de este ejercicio, ¿estamos en presencia de un ejercicio democrático, con posibilidades reales y jurídicas de aplicar para este sexenio?</span></p><br/>

<p><span data-contrast="auto">Empecemos por lo básico: el actual presidente de <strong>México, Andrés Manuel López Obrador</strong>, fue electo en el año 2018, mientras que el Decreto por el cual se reformaron y adicionaros diversas disposiciones constitucionales fue publicado el 20 de diciembre de 2019, entrando en vigor un día después. ¿Esta reforma le resulta aplicable al sexenio ya iniciado? </span></p><br/>
<p><span data-contrast="auto">Y aunque la respuesta parece contenerse en el texto del propio Decreto reformador, el cual establece la posibilidad de que la revocación de mandato se solicite respecto del periodo constitucional 2018-2021, la cuestión no es tan obvia como parece.</span></p><br/>
<p><span data-contrast="auto">En estricto sentido jurídico, y ante la afectación que pudiera causar un resultado no esperado y poco probable (pensemos que hipotéticamente triunfa la opción de revocar el mandato con un resultado vinculante), sería válido que el presidente acudiera ante la instancia judicial con el argumento de la no aplicación retroactiva de la norma jurídica en perjuicio de su mandato constitucional (consolidado mas de un año antes de la entrada en vigor de esta reforma), menudo problema tendría frente de si nuestro tribunal constitucional. Desde el punto de vista de la interpretación y argumentación constitucional y legal, existen buenos argumentos para defender cada una de las posturas contrapuestas.</span></p><br/>
<p><span data-contrast="auto">Y es que más allá de lo posible, o no, de este ejercicio hipotético; su utilidad radica en que podría tratarse de un “as bajo la manga” que nos ayude a entender por qué nuestro primer ejercicio de revocación de mandato en México sucede más como un ejercicio de ratificación al poder actual.</span></p><br/>
<p><span data-contrast="auto">En resumen, se trata de un ejercicio donde el presidente <strong>López Obrador</strong> podría aplicar el dicho “no hay nada que perder” y tal vez -sólo tal vez- “ sí mucho por ganar”. Esto es así, pues no solo se sabe que es poco probable (por no decir casi imposible) que el ejercicio conduzca a una revocación de mandato, sino que además cuenta con un argumento jurídico que posibilita -ante un escenario adverso- que la norma no le resulte aplicable. </span></p><br/>

<p><span data-contrast="auto">¿Sabrá el presidente y su equipo esta posibilidad? ¿Estarán jugando un juego que saben no hay forma de perder? Sin duda, en palacio nacional saben y entienden de política y derecho ¿por qué insistirá la oposición en acusar su ignorancia y no mejor en evidenciar los usos que dan a dicho conocimiento? ¿A quién le conviene más un “mal resultado” en este primer ejercicio de revocación de mandato? </span></p><br/>

</Info>





          <Title style={{marginTop:25}}>RAZON Y LEY </Title>

          <Category>El Aeropuerto Internacional Felipe Ángeles ¿Aires de cambio o vientos de esperanza política?</Category>
          <CreationDate> 21 de marzo del 2022</CreationDate>

          <AuthorInfo>
            <AuthorImage src={Fernando} />
            <AuthorNameAndProfession>
              <AuthorName><b>Fernando Zúñiga</b></AuthorName>
              <AuthorProfile>Socio</AuthorProfile>
            </AuthorNameAndProfession>
          </AuthorInfo>




        <Info style={{textAlign: 'justify'}}>
          <p aria-level="1"><span data-contrast="auto">En la política los simbolismos y los mensajes, que con ellos puedan darse, son importantes. El presidente López Obrador no sólo lo sabe, sino que lo aprovecha; por ello, este 21 de marzo -en el marco de los festejos por el natalicio</span><span>&nbsp;</span><span data-contrast="auto">216 de Benito Juárez- ha inaugurado una de sus obras emblemáticas: el <strong>Aeropuerto Internacional Felipe Ángeles (AIFA).</strong></span><strong>&nbsp;</strong></p><br/>
          <p><span data-contrast="auto">Esta obra es importante en muchos sentidos. Primero, el aeropuerto representa un “aire de cambio” pues simbolizó un rompimiento respecto de la administración de Enrique Peña, se trataba de iniciar una obra de infraestructura que se hiciera de forma diferente a las anteriores: con un menor costo, sin denuncias de corrupción y que la gente le sintiera cercana. Como promesa, se trató de una propuesta de campaña consistente en cancelar el aeropuerto de <strong>Texcoco</strong> -bajo argumentos de corrupción- y plantear como alternativa un aeropuerto en la base área militar de <strong>Santa Lucía</strong>; como proyecto, ésta fue impulsada por el presidente electo y soportada en los resultados de una “consulta al pueblo” (que no tuvo criterios claros de organización y que contó con muy poca participación); como decisión, ésta fue concretada -incluso- antes de tomar posesión como presidente constitucional de nuestro país.</span><span data-ccp-props="{&quot;335559685&quot;:119,&quot;335559737&quot;:114,&quot;335559738&quot;:1}">&nbsp;</span><span data-ccp-props="{&quot;335559738&quot;:9}">&nbsp;</span></p><br/>
          <p><span data-contrast="auto">Segundo, la fecha de inauguración de la obra es clave; no sólo se presenta como una obra construida en tiempo record (pese a todos los malos deseos de sus opositores), sino que coincide -con precisión casi milimétrica- con el proceso de revocación de mandato y con el año de renovación de 6 gubernaturas, ambos de total relevancia para los proyectos de la Cuarta Transformación.</span><span data-ccp-props="{&quot;335559685&quot;:119,&quot;335559737&quot;:114}">&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p><br/>
          <p><span data-contrast="auto">Tercero, su ubicación (sumada a su fecha efectiva de operación) es otro aspecto fundamental; la mega obra de infraestructura se ubica en el <strong>Estado de México</strong>, entidad que habrá de tener elecciones para renovar el ejecutivo local en 2023. No es difícil advertir que nuestro estado parece ser la joya de la corona para el proyecto encabezado desde el Ejecutivo Federal.</span><span data-ccp-props="{&quot;335559685&quot;:119,&quot;335559737&quot;:113}">&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p><br/>
          <p><span data-contrast="auto">Pero, no se debe pensar mal de una obra sin analizarla. Para construir este nuevo aeropuerto fue necesario cancelar el aeropuerto de Texcoco y esa decisión nos costo, de acuerdo con la Auditoria Superior de la Federación, 113 mil 327 millones de pesos (no 331 mil 966 millones de pesos, como originalmente había dicho esa misma entidad). Ahora bien, el costo de construcción del Aeropuerto Internacional Felipe Ángeles se estima, con cifras de la Secretaría de Hacienda y Crédito Público, en poco mas de 84 mil 956 millones de pesos (no 79 mil millones de pesos, cifra originalmente planteada). ¿Será este el costo de los vientos de esperanza política?</span><span data-ccp-props="{&quot;335559685&quot;:119,&quot;335559737&quot;:113}">&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p><br/>
          <p><span data-contrast="auto">Lo cierto es que el Aeropuerto Internacional Felipe Ángeles no sólo avanzó, sino que se concretó (cosa rara en muchos proyectos de infraestructura). Ya en funcionamiento será interesante si éste se utiliza, o no, en el discurso de <strong>Morena</strong> para el <strong>Estado de Méxic</strong>o como un detonante electoral, muestra de su presencia <span><span>y capacidad política; o si, por el contrario, es el <strong>PRI</strong> quien es capaz </span><span class="NormalTextRun SCXW30265144 BCX0" data-ccp-parastyle="Body Text">de </span><span class="NormalTextRun SCXW30265144 BCX0" data-ccp-parastyle="Body Text">contrarrestar</span> <span>dicho</span> <span>discurso</span> <span class="NormalTextRun SCXW30265144 BCX0" data-ccp-parastyle="Body Text">y</span> <span class="NormalTextRun SCXW30265144 BCX0" data-ccp-parastyle="Body Text">de</span> <span class="NormalTextRun SCXW30265144 BCX0" data-ccp-parastyle="Body Text">aprovechar</span> <span class="NormalTextRun SCXW30265144 BCX0" >en</span> <span>su</span> <span>beneficio</span> <span>esta</span> <span>obra.</span> <span>El</span> <span>tiempo, los manejos mediáticos y la operación de la terminal área serán quienes despejen dudas… ¿servirá </span><span>el aeropuerto como punto de despegue de un proyecto político transformador en nuestro estado o será que éste no tome vuelo… por falta de la pilota</span> <span >adecuada?</span></span><span>&nbsp;</span></span></p><br/>
       </Info>
        



             <Title style={{marginTop:25}}>RAZON Y LEY </Title>
          <Category>Propaganda gubernamental. No es lo mismo gobernar que ser oposición.</Category>
          <CreationDate> 14 de marzo del 2022</CreationDate>

          <AuthorInfo>
            <AuthorImage src={Fernando} />
            <AuthorNameAndProfession>
              <AuthorName><b>Fernando Zúñiga</b></AuthorName>
              <AuthorProfile>Socio</AuthorProfile>
            </AuthorNameAndProfession>
          </AuthorInfo>

          <Info style={{textAlign: 'justify'}}>
                  <p>
                    <p><span data-contrast="auto"> El pasado jueves, las y los legisladores afines a la cuarta transformación ejemplificaron que “no es lo mismo ser torero que ver los toros desde la barrera” y nos demostraron lo cambiante que son las posiciones en nuestro sistema político.</span></p> <br/> 
                <span data-contrast="auto">Hagamos un poco de memoria… en 2006, el entonces presidente Vicente Fox y otros muchos actores políticos (y hasta privados) no se cansaron de intervenir en la elección presidencial para favorecer al candidato oficial y perjudicar al principal oponente político: <strong>López Obrador.</strong> En aquel entonces -y con razones de sobra- la oposición demandaba silencio y no intervención, era evidente que aquellos servidores públicos, comandados por el presidente de la república, se entrometían en procesos electorales y eso vulneraba la equidad de la competencia.</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:115}">&nbsp;</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:9}">&nbsp;</span></p><br/>
                <p><span data-contrast="auto">La consecuencia de esa abierta intervención fue una reforma electoral que, entre muchas otras cosas, establecía un modelo conocido como <strong>“veda electoral”</strong> cuyo objetivo consistía en “silenciar” a los poderes públicos durante los procesos electorales para que ninguno pudiera intervenir en favor o en contra de alguna de las opciones políticas. Desde entonces, en el año 2007, la Constitución ordenó a los legisladores regular el tema de la propaganda electoral.</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:114,&quot;335559738&quot;:1}">&nbsp;</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:2}">&nbsp;</span></p><br/>
                <p><span data-contrast="auto">Tuvieron que pasar casi 15 años, y muchos cambios políticos, para que la regulación por fin se hiciera. Lo sorprendente no es el tiempo que pasó, sino la forma en que llegó: en menos de 8 horas, sin mayor discusión, la mayoría de los ahora legisladores oficiales -afines al propio<strong> López Obrador</strong>– decidieron interpretar el concepto de propaganda gubernamental. Lo sorprendente es que su interpretación fuera más parecida -en argumentos- a lo que defendía Vicente Fox en 2006 que a lo que el propio líder opositor de aquel entonces –<strong>López Obrador- sostenía</strong>.</span><span data-contrast="auto">1</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:114}">&nbsp;</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559738&quot;:9}">&nbsp;</span></p><br/>
                <p><span data-contrast="auto">El resumen de la propuesta es: cualquier servidor público podrá “expresar su opinión” respecto de campañas políticas o procesos de revocación de mandato sin ser sancionado por ello; el único límite es no utilizar recursos públicos para hacerlo (lo cual en la práctica resulta muy difícil de probar y castigar). Será importante observar qué pasa en el Senado con esta propuesta.</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:113}">&nbsp;</span></p><br/>
                <p><span data-contrast="auto">¿Se imaginan que hubiera pensado la oposición de 2006 con esta norma? Para darnos una idea, Vicente Fox podría actuar como lo hizo sin reproche legal alguno, ¡Vaya avance después de 15 años!</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:114,&quot;335559738&quot;:1}">&nbsp;</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6}">&nbsp;</span></p><br/>
                <p><span data-contrast="auto">Para aderezar la incongruencia política, las y los legisladores de<strong> Acción Nacional</strong></span><strong>&nbsp;</strong></p><br/>
                <p><span data-contrast="auto">-hoy oposición- han advertido que -de aprobarse la modificación legal- acudirán ante la Suprema Corte de Justicia de la Nación para presentar una <strong>Controversia Constitucional</strong> pues consideran que esta propuesta es antidemocrática, en formas y fondo.</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:114,&quot;335559738&quot;:3}">&nbsp;</span></p><br/>
                <p><span data-contrast="auto">Las y los legisladores de la cuarta transformación deberían recordar que la ruleta política de los triunfos electorales da muchas vueltas y en alguna puede tocarles</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:114,&quot;335559740&quot;:242}">&nbsp;</span></p><br/>
                <p><span data-contrast="auto">-de nuevo- ser oposición ¿Lamentarán, entonces, haber aprobado la ley que hoy festejan?… ya veremos que dice el Senado, primero y la historia, después.</span><span data-ccp-props="{&quot;335551550&quot;:6,&quot;335551620&quot;:6,&quot;335559685&quot;:119,&quot;335559737&quot;:114,&quot;335559740&quot;:242}">&nbsp;</span></p><br/>
                <p>&nbsp;</p><br/>
                1 Vale la pena mencionar que, ante la ausencia de regulación en este tema, ha sido el Tribunal Electoral del Poder Judicial de la Federación quien ha definido los alcances y limitaciones a la propaganda gubernamental en procesos electorales Lo anterior, ejerciendo su facultad como interprete último de las normas en la materia.
                  </Info>




                  {/** post 1 */}



                  <Title style={{marginTop:25}}>RAZON Y LEY</Title>
          <Category>Paridad Política como destino</Category>
          <CreationDate> 7 de marzo del 2022</CreationDate>

          <AuthorInfo>
                    <AuthorImage src={Fernando} />
                    <AuthorNameAndProfession>
                      <AuthorName><b>Fernando Zúñiga</b></AuthorName>
                      <AuthorProfile>Socio</AuthorProfile>
                    </AuthorNameAndProfession>
                  </AuthorInfo>

          <Info style={{textAlign: 'justify'}}>
          En el sonar de las voces de marzo, resulta inevitable escribir de su conmemoración más importante: el Día Internacional de las Mujeres. Día de lucha y reivindicaciones para ellas; día de reflexión y respeto de nuestra parte. <br/><br/> 

Una de las primeras demandas de la lucha feminista, sino es que la raíz de ella fue su reconocimiento como ciudadanas. Con todo lo que esto implica, el derecho a votar y ser votadas, era el punto de partida. En este sentido, mucho se habla de la paridad política, se presume que la hemos alcanzado; incluso, existen denominaciones como “legislatura de la paridad”, pero ¿Hemos sido capaces de transformar las demandas de igualdad de género en una realidad política permanente? La respuesta es concluyente, NO. Y aunque no hemos llegado a ese destino, vale la pena revisar algunos avances; como un primer ejercicio, miremos las cifras. <br/>  <br/> 

Las elecciones de junio de 2021 perfilaron buenos resultados en cuanto representación política legislativa: La Cámara de Diputados Federal es -por primera vez en toda su historia- conformada de forma efectivamente paritaria: <br/> <br/> 

250 mujeres y 250 hombres. En el ámbito local, la legislatura mexiquense alcanzo una paridad virtual: 38 hombres y 37 mujeres y el mandato en una sentencia judicial de que la próxima legislatura deberá estar conformada – obligatoriamente- por 38 mujeres y 37 hombres, será vital vigilar su cumplimiento en 2024.  <br/> <br/> 

En temas relacionados con el poder ejecutivo, la paridad tuvo -también- un avance importante, 6 mujeres lograron la preferencia electoral y -con ello- México tiene hoy 7 gobernadoras, en lugar de 2 que tenia al inicio de 2021.1 Este dato deberá ser recordado, y pronto, en nuestra entidad, donde la deuda histórica es contundente: el Estado de México no ha tenido nunca una mujer gobernadora.  <br/> <br/> 

Ahora bien, si revisamos las presidencias municipales del Estado de México, tras las elecciones de 2021, 62% de éstas son ocupadas por hombres (77 presidencias municipales) y sólo 38% por mujeres (47 presidencias municipales).2 Y aunque el dato muestra avances en materia de paridad con respecto de 2018 (donde las presidentas municipales mujeres eran 39) aún se nota una oportunidad de mejora importante.  <br/> <br/> 

Con todos estos datos, es evidente que el siguiente paso para la paridad en nuestro Estado es claro: una mujer gobernadora en 2023 y más mujeres en las presidencias municipales en 2024. En el mediano plazo, se debe analizar más allá de las cifras; no sólo se requiere de más mujeres ocupando cargos de decisión, sino decidiendo en beneficio de ellas. Sin embargo, para que se pueda legislar por ellas y para ellas se necesita su voz, en ese camino se sigue transitando.  <br/> <br/> 

¿Sera posible alcanzar la paridad política como destino? Yo pienso que sí y uno de los últimos diques para alcanzar dicha paridad será que el Estado de México sea gobernado por una mujer a partir de 2023, con mayoría de presidentas municipales mujeres, a partir de 2024. Se aproximan años decisivos para alcanzar la paridad política como destino en nuestra entidad. <br/> <br/> 
            
             </Info>


       
        </ContentWithPaddingLg>


      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
